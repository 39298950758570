import React,{createContext,useReducer,useEffect} from 'react'
import PropertyReducer from './PropertyReducer';



const initialState = {
  locationValue:[],
   facilities:[],
   roomDetails:[],
   propImages:[],
   profileDetails:[],
  basic:[],
  paymentVerified:true,
  upiverified:true,
  basicverify:false,
  profileacverified:false,
  profileupiverified:false,
  upi_name:{},
  bankac_name:{},
 gstvarified_details:[],
 propImagespath:[],
 profileImagePath:[],
 latlong:{ lat: 28, lng: 77 },
 idforUpdation:"",
 changeschecking:[],
 screenimages:[],
 property_aggreid:[],
 oldupiid:{},
 deleteimages:[],
 logo_img:"https://portalvhds4prl9ymlwxnt8.blob.core.windows.net/img/HBD_logo.png",
 //"https://endpoint887127.azureedge.net/pimages/OrTFH2wg_newhblogo_250x150.png",
 onboardapi:`https://myhotelapi.hummingbirdindia.com/API`,
 warsoftapi:'https://warsoftapi.warsoft.in/API',
 property_update: `https://myhotelapi.hummingbirdindia.com/API`
}
//'http://localhost:8000/api'|| 'http://localhost:3000/api'||https://myhotelapi.hummingbirdindia.com/API
//basic,locationValue,facilities,roomDetails,propImages,profileDetails



export const PropertyContext = createContext(initialState);

export const PropertyProvider = (props) => {

    const [state,dispatch] = useReducer(PropertyReducer,initialState);

    


  const addToBasic = (values) => {
    dispatch({ type: "ADD_TO_Basic", payload: values });

  };

   const addToLocations = (values) => {
    dispatch({ type: "ADD_TO_LOCATIONS", payload: values });
  };

  const addToFacilities = (values) => {
    dispatch({ type: "ADD_TO_FACILITIES", payload: values });
  };
  const addToRoomDetails = (values) => {
    dispatch({ type: "ADD_TO_ROOMDETAILS", payload: values });
  };
  const addToPropImages = (values) => {
    dispatch({ type: "ADD_TO_PROPIMAGES", payload: values });
  };
  const addToPropImagespath = (values) => {
    dispatch({ type: "ADD_TO_PROPIMAGES_PATH", payload: values });
  };
  // deleted images 
  const deletedpropimages = (values) => {
    dispatch({ type: "DELETED_IMAGES", payload: values });
  };
  const addToProfile = (values) => {
    dispatch({ type: "ADD_TO_Profile", payload: values });
  };
  const payment_verified = (values) => {
    dispatch({ type: "ADD_TO_PAYMENT_VERIFIED", payload: values });

  };
  const upi_verified = (values) => {
    dispatch({ type: "ADD_TO_UPI_VERIFIED", payload: values });

  };
  //verifybut
  const basic_Verifiedbut = (values) => {
    dispatch({ type: "BASIC_VERIFIED", payload: values });
  };
  const profile_acVerifiedbut = (values) => {
    dispatch({ type: "PROFILE_AC_VERIFIED", payload: values });
  };
  const profile_upi_Verifiedbut = (values) => {
    dispatch({ type: "PROFILE_UPI_VERIFIED", payload: values });
  };
  const upiNamefunc = (values) => {
    dispatch({ type: "VERIFIED_UPI_DETAILS", payload: values });
  };
  const bankNamefunc = (values) => {
    dispatch({ type: "VERIFIED_Bank_DETAILS", payload: values });
  };
  const basic_GST_verified = (values) => {
    dispatch({ type: "VERIFIED_GST_DETAILS", payload: values });
  };
  const profileImagePathfunc = (values) => {
    dispatch({ type: "PROFILE_IMAGE_DETAILS", payload: values });
  };

  // data for updation
  const idforUpdationfunc = (values) => {
    dispatch({ type: "ID_FOR_UPDATION", payload: values });
  };

  // changes checking
  const changescheckingfunc = (values) => {
    dispatch({ type: "CHANGES_CHECKING", payload: values });
  };
  
  // changes checking
  const photasscreenimages = (values) => {
    dispatch({ type: "IMAGE_UPLOAD", payload: values });
  };
   // changes checking
   const oldupiidfunc = (values) => {
    dispatch({ type: "UPI_ID", payload: values });
  };

  //Enter number only
  const textNum = (e) => {
    return e.target.value?.replace(/\D/g,'')
    }
    //remove space
  const propInput = (e) => {
    if(/^\s/g.test(e.target.value)){
    e.target.value = e.target.value?.replace(/^\s/g, "");
    
     }
     else {
      e.target.value = e.target.value?.replace(/^[ \t]+|[ \t]+$/gm, " ");
     }
    }
    const propInput1 = (e) => {
      if(/^\s/g.test(e.target.value)){
      e.target.value = e.target.value?.replace(/^\s/g, "");
      
       }
       else {
        e.target.value = e.target.value?.replace(/^[ \t]+|[ \t]+$/gm, "");
       }
      }
//multi space trim
const multispacetrim = (value) => {
  return value?.replace(/  /g,' ').trim()
  }
  //lat long
const getLatLongfunc = (values) => {
  dispatch({ type: "GET_LAT_LONG", payload: values });
  }

  //
  const percentagevalidate = (e) => {
    if (isNaN(e.target.value)) {
      e.target.value = e.target.value.substring(0, e.target.value.length - 1);   
   }
  }

  const propertyaggreidfunc = (values) => {
    dispatch({ type: "PROP_AGGRE_ID", payload: values });

  }

    return (
        <PropertyContext.Provider 
        value = {{
            basic:state.basic,
            addToBasic,
            locationValue:state.locationValue,
            addToLocations,
            facilities:state.facilities,
            addToFacilities,
            roomDetails:state.roomDetails,
            addToRoomDetails,
            propImages:state.propImages,
            addToPropImages,
            profileDetails:state.profileDetails,
            addToProfile,
            paymentVerified:state.paymentVerified,
            payment_verified,
            upiverified:state.upiverified, 
            upi_verified,
            basicverify:state.basicverify,
            basic_Verifiedbut,
            profileacverified:state.profileacverified,
            profile_acVerifiedbut,
            profileupiverified:state.profileupiverified,
            profile_upi_Verifiedbut,
            upi_name:state.upi_name,
            upiNamefunc,
            bankac_name:state.bankac_name,
            bankNamefunc,
            gstvarified_details:state.gstvarified_details,
            basic_GST_verified,
            propImagespath:state.propImagespath,
            addToPropImagespath,
            profileImagePath:state.profileImagePath,
            profileImagePathfunc,
            textNum,
            propInput,
            multispacetrim,
            latlong:state.latlong,
            getLatLongfunc,
            idforUpdation:state.idforUpdation,
            idforUpdationfunc,
            percentagevalidate,
            changeschecking:state.changeschecking,
            changescheckingfunc,
            photasscreenimages,
            screenimages:state.screenimages,
            propInput1,
            propertyaggreidfunc,
            property_aggreid:state.property_aggreid,
            oldupiidfunc,
            oldupiid:state.oldupiid,
            onboardapi:state.onboardapi,
            warsoftapi:state.warsoftapi,
            property_update:state.property_update,
            deletedpropimages,
            deleteimages:state.deleteimages,
            logo_img:state.logo_img

        }}>{props.children}</PropertyContext.Provider>
    )
}

